// Privacy Policy
body.privacy {
	background-color: #f4f4f4;
	padding:pc-vh(120) pc-vw(150) pc-vh(139) pc-vw(150);
	color:#111;
	@include mobile {
		padding:0;
	}
	.privacy-header {
		@include mobile {
			padding:rem(60) 0 rem(20) 0;
		}
		.privacy-logo {
			margin-bottom:pc-vh(60);
			display: flex;
			justify-content: center;
			@include mobile {
				margin-bottom:0;
			}
			a {
				position: relative;
				display: block;
				aspect-ratio: 10/2.5;
				height:pc-vh(75);
				text-indent: -9999px;
				@include mobile {
					height:pc-vh(40);
				}
				&::after {
					content: '';
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height:100%;
					background: $img-logo-black 0 0 / contain no-repeat;
				}
			}
		}
	}
	.inner {
		background-color: #fff;
		border-radius: rem(30);
		padding:pc-vw(80);
		@include mobile {
			padding:rem(48) rem(20);
			border-radius: rem(20) rem(20) 0px 0px;
		}
	}
}
.privacy-container {
	text-align: left;
	a {
		color:#000;
		font-weight: 500;
		text-decoration: underline;
		text-underline-position: under;
	}
	h1 {
		margin-bottom:pc-vh(60);
		font-size:pc-vw(50);
		font-weight: 600;
		@include mobile {
			margin-bottom:rem(40);
			font-size:rem(20);
			letter-spacing: -0.01em;
		}
	}
	h2 {
		margin-bottom:pc-vh(40);
		font-size:pc-vw(20, 17);
		font-weight: 600;
		@include mobile {
			margin-bottom:rem(20);
			font-size:rem(14);
			letter-spacing: -0.01em;
		}
	}
	p.main-p {
		margin-bottom:pc-vh(20);
		font-size:pc-vw(14, 12);
		line-height: 1.42;
		@include mobile {
			margin-bottom:rem(20);
			font-size:rem(12);
			letter-spacing: -0.02em;
		}
	}
	p.no-margin {
		margin-left:rem(-20);
		@include mobile {
			margin-left:0;
		}
	}
	p.list-title {
		margin-bottom:pc-vh(12);
		font-size:pc-vw(14, 12);
		line-height: 1.42;
		@include mobile {
			margin-bottom:rem(20);
			font-size:rem(12);
			letter-spacing: -0.02em;
		}
	}
	ol.small, ul.small {
		@include mobile {
			margin-bottom:rem(20);
		}
		>li {
			position: relative;
			font-size:pc-vw(14, 12);
			color:#959595;
			&.em {
				text-decoration: underline;
				text-underline-position: under;
				color:#000;
			}
		}
	}
	ol.main {
		margin-left:rem(20);
		counter-reset: counter;
		>li {
			position: relative;
			margin-top:pc-vh(80);
			@include mobile {
				margin-top:rem(40);
			}
			&:before {
				counter-increment: counter 1;
				content:counter(counter , decimal) '.';
				position: absolute;
				top:rem(0);
				left:rem(-20);
				display: inline-block;
				font-size:pc-vw(16, 14);
				font-weight: 600;
				line-height: 1.25;
			}
			p.main-list-title {
				width:calc(100% - rem(20));
				margin-bottom:pc-vh(20);
				font-size:pc-vw(16, 14);
				font-weight: 600;
				line-height: 1.25;
				@include mobile {
					margin-bottom:rem(20);
				}
			}
			> p.list-title {
				margin-left:rem(-20);
			}
		}
	}
	ol.capital {
		counter-reset: counter;
		>li {
			position: relative;
			margin-bottom:pc-vh(12);
			font-size:pc-vw(14 , 12);
			p.list-title {
				@include mobile {
					margin-bottom:rem(20);
					font-size:rem(14);
				}
			}
			&:before {
				counter-increment: counter 1;
				content:counter(counter , upper-alpha) '.';
				position: absolute;
				top:rem(0);
				left:rem(-20);
				display: inline-block;
			}
		}
	}
	ol.lowercase {
		counter-reset: counter;
		>li {
			position: relative;
			@include mobile {
				margin-bottom:rem(6);
			}
			&:before {
				counter-increment: counter 1;
				content:counter(counter , lower-alpha) '.';
				position: absolute;
				top:rem(0);
				left:rem(-20);
				display: inline-block;

			}
		}
	}
	ol.number {
		margin-left:rem(20);
		counter-reset: counter;
		>li {
			position: relative;
			&:before {
				counter-increment: counter 1;
				content:counter(counter , decimal) '.';
				position: absolute;
				top:rem(0);
				left:rem(-20);
				display: inline-block;
			}
			@include mobile {
				margin-bottom: rem(6);
			}
		}
	}
	ol.parental-digits {
		margin-top:pc-vh(12);
		counter-reset: counter;
		>li {
			position: relative;
			@include mobile {
				margin-bottom:rem(6);
			}
			&:before {
				counter-increment: counter 1;
				content:counter(counter , decimal) ')';
				position: absolute;
				top:rem(0);
				left:rem(-20);
				display: inline-block;

			}
		}
	}
	ul.bullet {
		margin-left:rem(-10);
		>li {
			position: relative;
			margin:pc-vh(8) 0 0 0;
			@include mobile {
				margin-top:rem(0);
				margin-bottom:rem(6);
				margin-left:rem(12);
			}
			&:before {
				content: '';
				position: absolute;
				top:rem(10);
				left:rem(-10);
				width:rem(3);
				height:rem(3);
				border-radius: 100%;
				background-color: #959595;
				@include mobile {
					content:'-';
					top:0;
					left:rem(-20);
					width:rem(10);
					height:rem(10);
					border-radius: 0;
					background-color: unset;
				}
			}
		}
	}
	table {
		width: 100%;
		margin-left: -2rem;
		margin-top: pc-vh(8);
		font-size: pc-vw(14, 12);
		line-height: 1.42;
		th, td {
			padding: pc-vh(8) pc-vw(12);
			border: 1px solid #e0e0e0;
		}
		th {
			background: #f2f2f2;
		}
		td {
			vertical-align: middle;
		}
	}
}