// Viewport Size
$viewport-desktop: 1920px;
$viewport-laptop: 1500px;
$viewport-tab: 1024px;
$viewport-mobile: 1023px;

// PC Design Size
$design-master-vw: 1920;
$design-master-vh: 1080;

// Multilingual Font Definition
$font-en: 'Inter'; // English
$font-de: 'Inter'; // Deutsch
$font-es: 'Inter'; // Español
$font-ja: 'Inter'; // 日本語

// etc
$sub-main-color : #001AFF;
$sub-black-color : #111;

// Path, URL, SVG Data
$url-font: '../font/';
$url-img: '../img/';
$img-logo: url($url-img + 'common/logo.svg');
$img-logo-black: url($url-img + 'common/logo-black.svg');
$svg-round-30-lt: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' stroke-width='0' fill='#111'><path d='M14.999939,0H0v15C0,6.715698,6.715637,0,14.999939,0Z'/></svg>");
$svg-round-30-rt: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' stroke-width='0' fill='#111'><path d='M0,0c8.284302,0,15,6.715698,15,15V0H0Z'/></svg>");
$svg-round-30-lb: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' stroke-width='0' fill='#111'><path d='M0,0v15h14.999939C6.715637,15,0,8.284241,0,0Z'/></svg>");
$svg-round-30-rb: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' stroke-width='0' fill='#111'><path d='M0,15h15V0c0,8.284241-6.715698,15-15,15Z'/></svg>");
$svg-youtube: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 30' stroke-width='0' fill='white'><path d='M35.250198,6.40404c-.414799-1.53751-1.632797-2.74576-3.182697-3.157333-2.809401-.746707-14.067501-.746707-14.067501-.746707,0,0-11.25815,0-14.06454.746707-1.54988.411573-2.77082,1.619823-3.182744,3.157333-.752716,2.78399-.752716,8.59596-.752716,8.59596,0,0,0,5.812.752716,8.596001.414884,1.537498,1.632864,2.745699,3.182744,3.157299,2.80639.7467,14.06454.7467,14.06454.7467,0,0,11.258101,0,14.064499-.7467,1.5499-.4116,2.767902-1.619801,3.1828-3.157299.752701-2.784.752701-8.596001.752701-8.596001,0,0,0-5.81197-.752701-8.59596h.002899ZM14.3994,20.3563v-10.7126l9.3527,5.3563-9.3527,5.3563Z'/></svg>");
$svg-instagram: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30' stroke-width='0' fill='white'><path d='M21.3397 0H8.65822C3.87665 0 0 3.87665 0 8.65822V21.3397C0 26.1212 3.87665 29.9979 8.65822 29.9979H21.3397C26.1212 29.9979 29.9979 26.1212 29.9979 21.3397V8.65822C30 3.87665 26.1233 0 21.3397 0ZM27.2364 21.5627C27.2364 24.7362 24.664 27.3108 21.4884 27.3108H8.50952C5.33598 27.3108 2.76145 24.7384 2.76145 21.5627V8.58387C2.76145 5.41032 5.33385 2.8358 8.50952 2.8358H21.4884C24.6619 2.8358 27.2364 5.4082 27.2364 8.58387V21.5627Z'/><path d='M14.9989 7.29004C10.6996 7.29004 7.21588 10.7758 7.21588 15.0731C7.21588 19.3703 10.7017 22.8561 14.9989 22.8561C19.2962 22.8561 22.782 19.3703 22.782 15.0731C22.782 10.7758 19.2962 7.29004 14.9989 7.29004ZM14.9989 20.1202C12.212 20.1202 9.95397 17.86 9.95397 15.0752C9.95397 12.2904 12.2141 10.0303 14.9989 10.0303C17.7837 10.0303 20.0439 12.2904 20.0439 15.0752C20.0439 17.86 17.7837 20.1202 14.9989 20.1202Z'/><path d='M23.0431 8.80879C24.0449 8.80879 24.8571 7.99661 24.8571 6.99473C24.8571 5.99285 24.0449 5.18066 23.0431 5.18066C22.0412 5.18066 21.229 5.99285 21.229 6.99473C21.229 7.99661 22.0412 8.80879 23.0431 8.80879Z'/></svg>");
$svg-arrow-go : svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' stroke-width='0' fill='white'><path d='M20.862305,11.968262l-6.291016-6.260254c-.293945-.291992-.767578-.291016-1.060547.00293-.292969.293457-.291016.768066.001953,1.060547l4.982544,4.958008H4.666504c-.414062,0-.75.335938-.75.75s.335938.75.75.75h13.870056l-5.023865,5c-.292969.291992-.294922.766602-.001953,1.060547.146484.147461.338867.220703.53125.220703.191406,0,.382812-.073242.529297-.21875l6.291016-6.260742c.141602-.140625.220703-.331543.220703-.53125s-.079102-.391113-.220703-.531738Z'/></svg>");
$svg-arrow-go-black : svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' stroke-width='0' fill='#111 '><path d='M20.862305,11.968262l-6.291016-6.260254c-.293945-.291992-.767578-.291016-1.060547.00293-.292969.293457-.291016.768066.001953,1.060547l4.982544,4.958008H4.666504c-.414062,0-.75.335938-.75.75s.335938.75.75.75h13.870056l-5.023865,5c-.292969.291992-.294922.766602-.001953,1.060547.146484.147461.338867.220703.53125.220703.191406,0,.382812-.073242.529297-.21875l6.291016-6.260742c.141602-.140625.220703-.331543.220703-.53125s-.079102-.391113-.220703-.531738Z'/></svg>");
$svg-arrow-toggle: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke-width='0' fill='white'><path d='M8,10.5c-.12793,0-.255859-.048828-.353516-.146484L3.646484,6.353516c-.195312-.195312-.195312-.511719,0-.707031s.511719-.195312.707031,0l3.646484,3.646484,3.646484-3.646484c.195312-.195312.511719-.195312.707031,0s.195312.511719,0,.707031l-4,4c-.097656.097656-.225586.146484-.353516.146484Z'/></svg>");
$svg-play: url($url-img + 'common/movie-play.svg');
$svg-sound-on: url($url-img + 'common/sound-on.svg');
$svg-sound-off: url($url-img + 'common/sound-off.svg');
$svg-lang: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' stroke-width='0' fill='white'><path d='M22,12c0-5.51416-4.486328-10-10-10-.00061,0-.00116.000061-.00177.000061-.000366,0-.000793-.000061-.00116-.000061h-.004883c-.004272,0-.008362.000977-.012634.001038-5.5047.01123-9.979553,4.491699-9.979553,9.998962,0,5.513672,4.48584,10,10,10,.000488,0,.000977-.000061.001465-.000061.000305,0,.000671.000061.000977.000061h.005859c.004578,0,.008911-.001038.013489-.001099,5.50354-.011963,9.97821-4.492615,9.97821-9.998901ZM19.930542,11h-3.984314c-.102539-2.278259-.555542-4.517212-1.360596-6.562927,2.840088.973999,4.960266,3.499329,5.34491,6.562927ZM12.004211,19.999756c-.000549,0-.001221.000122-.00177.000122-.126099-.000366-.318054-.145752-.463867-.467651-.897217-1.995239-1.378784-4.248108-1.492798-6.532227h3.908203c-.114746,2.282776-.596741,4.536011-1.494019,6.53418-.14325.320679-.331726.464966-.45575.465576ZM10.046143,11c.114624-2.282471.596008-4.535278,1.492432-6.532715.000488-.000488.000488-.000977.000977-.001465.14325-.320679.332214-.464539.456238-.465637.000549,0,.001221-.000061.00177-.000061.12561.000366.317566.145264.463379.467651.897644,1.995178,1.379395,4.248047,1.493408,6.532227h-3.908203ZM9.414124,4.437134c-.80426,2.047058-1.25708,4.285828-1.360168,6.562866h-3.984497c.384583-3.063538,2.5047-5.588806,5.344666-6.562866ZM4.069458,13h3.984192c.102539,2.278259.55542,4.51709,1.360229,6.562744-2.839905-.974121-4.959839-3.499329-5.344421-6.562744ZM14.5849,19.563232c.804749-2.046936,1.257935-4.285889,1.361023-6.563232h3.984619c-.384644,3.063904-2.505249,5.589355-5.345642,6.563232Z'/></svg>");
$svg-contact: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' stroke-width='0' fill='#0a0488'><path d='M18.117188,28.392578c-.041992,0-.084961-.001953-.12793-.003906-1.032227-.052734-1.89502-.760742-2.147949-1.760742l-2.134766-8.514648-8.526367-2.146973c-1.011719-.255859-1.700684-1.095215-1.756348-2.138672-.055664-1.044922.541016-1.953613,1.52002-2.315918l19.625488-7.279297c.886719-.316895,1.832031-.101562,2.482422.557617.643555.65332.84375,1.592285.522461,2.450195l-7.279297,19.624023c-.344727.939453-1.19043,1.52832-2.177734,1.52832ZM15.641113,17.606445l2.13916,8.533203c.052734.208008.217773.24707.311523.251953.160156.004883.270508-.06543.326172-.217773.000977-.001953.000977-.003906.001953-.004883l7.280273-19.626953c.061523-.164551-.005859-.279785-.073242-.348145-.058594-.059082-.185547-.148926-.373047-.08252L5.638184,13.387207c-.19043.070801-.223145.220215-.217285.333984.005859.112305.054199.256836.248535.305664l8.564941,2.157227,10.960938-10.986328c.389648-.390137,1.023438-.391602,1.414062-.001953.391602.389648.391602,1.022949.001953,1.414062l-10.970215,10.996582Z'/></svg>");
$svg-top: url($url-img + 'common/icon-top.svg');
$svg-select: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'><path stroke='#868686' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M17.5 6.25L10 13.75L2.5 6.25'/></svg>");
$svg-check-off: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' fill='none'><path stroke='#C0C0C0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75C18.3848 22.75 22.75 18.3848 22.75 13Z'/><path stroke-linecap='round' stroke-linejoin='round' d='M17.3337 10.834L11.917 16.2507L8.66699 13.0007' stroke='#C0C0C0' stroke-width='1.5'/></svg>");
$svg-check-on: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' fill='none'><path fill='#000000' stroke='#000000' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' d='M22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75C18.3848 22.75 22.75 18.3848 22.75 13Z'/><path stroke-linecap='round' stroke-linejoin='round' d='M17.3337 10.834L11.917 16.2507L8.66699 13.0007' stroke='#ffffff' stroke-width='1.5'/></svg>");
$svg-arrow: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 6' fill='none'><path d='M1 1L6 5L11 1' stroke='white' stroke-width='1.5' stroke-linecap='round'/></svg>");
$svg-close: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><circle cx='12' cy='12' r='12' fill='#F4F4F4'/><path d='M8 8L16 16' stroke='black' stroke-linecap='round'/><path d='M8 16L16 8' stroke='black' stroke-linecap='round'/></svg>");
$svg-check-system: svg-uri("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none'><path d='M14.6023 5.1499L6.90234 12.8499L3.40234 9.3499' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");

$sns-list: youtube 1.2 $svg-youtube, instagram 1 $svg-instagram; // Share ICON
$font-list: Inter Light 300, Inter Regular 400, Inter Medium 500, Inter SemiBold 600, Inter Bold 700; // Web Fonts

$theme-dark-bg: #111;
$theme-dark-color: #fff;
$theme-dark-shadow: 0px 0px 10px 0px rgba(17,17,17,0.2);

$fm-bg: #F1F2F4;
$fm-placeholder: #C0C0C0;
$fm-line: #C0C0C0;

:root {
	--vh-100: 100vh; // 100vh ≠ window.innerHeight : [js] innerHeight 기준으로 vh 계산.
	--vh: 1vh;
	--base-font : #{$font-en}; // 언어별 폰트
	--base-color : #{$theme-dark-color}; // 기본 font color
	--base-bg : #{$theme-dark-bg}; // 기본 body 배경
	--delay : 0; // 애니메이션
	--logo-ratio: 1/0.16667;
	--space-h-content-m : calc(var(--space-h-content) * -1); // 컨텐츠 좌/우 여백 당기기
	@include desktop {
		--size-v-header : #{pc-vw(100, 70)}; // header vertical size
		--size-v-footer : #{pc-vw(94, 66)}; // footer vertical size
		--space-h-side : #{rem(100)}; // pc 언어선택 / Contact US 영역 사이즈
		--space-h-content : #{pc-vw(100)}; // 컨텐츠 좌/우 여백
		--size-content-max : #{rem(1240)}; // 일부 컨텐츠 영역 max 사이즈
		--size-content-max-2 : #{rem(1620)}; // 일부 컨텐츠 영역 max 사이즈
		--space-v-base : #{pc-vh(80)}; // 메인 컨텐츠 내 상/하 여백
		--space-v-sub : #{pc-vh(280)}; // 서브 컨텐츠 섹션 간격
		--radius-fm: #{rem(10)}; // form 요소들 radius
	}
	@include mobile {
		/* 임시 */
		--size-v-header : #{rem(60)};
		--size-v-footer : #{rem(135)};
		--space-h-side : 0vw;
		--space-h-content : #{rem(20)};
		--size-content-max : auto;
		--size-content-max-2 : auto;
		--space-v-base : #{rem(80)};
		--space-v-sub : #{rem(80)};
		--radius-fm: #{rem(4)};
	}
	--space-l-body : calc(var(--space-h-side) + var(--space-h-content));
}
html {
	&[lang="de"] {
		--base-font : #{$font-de};
	}
	&[lang="es"] {
		--base-font : #{$font-es};
	}
	&[lang="ja"] {
		--base-font : #{$font-ja};
	}
}


// Motion Time
$ani-time: 400ms;
$ani-text-time: 1000ms;

// Motion Easing
$ease-default: cubic-bezier(.645, .045, .355, 1);
$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-in-back: cubic-bezier(.600, -0.280, 0.735, 0.045);
$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ease-inout-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-inout-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-inout-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-inout-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ease-inout-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-inout-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-inout-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ease-inout-back: cubic-bezier(.680, -0.550, 0.265, 1.550);
$ease-out-slide: cubic-bezier(0.230, 1.000, 0.320, 1.000);