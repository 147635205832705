// 404
.error-container {
	position: fixed;
	top:var(--pd-v);
	left:0;
	width:100%;
	height:100%;
	.error-wrap {
		width:100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-bottom: var(--pd-v);
		padding-left: var(--space-h-content);
		padding-right: calc(var(--space-l-body) - var(--space-h-content)) ;
		text-align: center;
		@include mobile {
			padding-left: 0;
		}
		h2 {
			font-size:pc-vw(160);
			font-weight: 700;
			letter-spacing:-0.02em;
			line-height: 1.2;
			@include mobile {
				font-size:rem(80);
			}
		}
		p.title {
			font-size:pc-vw(40);
			font-weight: 600;
			letter-spacing:-0.02em;
			@include mobile {
				margin-top:rem(16);
				font-size:rem(24);
			}
			&.under-maintenance {
				font-size:pc-vw(60);
				@include mobile {
					font-size:rem(24);
				}
				&:before {
					content: '';
					display: block;
					width:pc-vw(94);
					aspect-ratio: 1/1;
					margin:0 auto pc-vw(40) auto;
					border-radius: 100%;
					background-color: #001Aff;
					background-image: url(../img/common/icon-underInspection.svg);
					background-position: center;
					background-repeat: no-repeat;
					background-size: pc-vw(34) pc-vw(34);
					@include mobile {
						width:rem(78);
						background-size: rem(28) rem(28);
					}
				}
			}
		}
		p.sub {
			display: flex;
			flex-direction: column;
			width:pc-vw(800);
			margin-top:pc-vh(12);
			margin-bottom:pc-vw(60);
			font-size:pc-vw(16, 12);
			color: #C0C0C0;
			@include mobile {
				width:calc(100% - rem(84));
				margin-top:rem(20);
				margin-bottom:0;
				font-size:rem(16);
			}
			&.under-maintenance {
				margin-bottom:pc-vw(72);
				@include mobile {
					margin-bottom:0;
				}
			}
		}
		a.btn-send {
			display: flex;
			align-items: center;
			justify-content: center;
			width:pc-vw(400);
			@include mobile {
				position:fixed;
				bottom:rem(40);
				width:calc(100% - rem(40));
				left:rem(20);
			}
		}
		span.hancom-logo {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			height: pc-vw(30);
			aspect-ratio: var(--logo-ratio);
			text-indent: -999px;
			&::before {
				content: '';
				display: block;
				height: pc-vw(30);
				aspect-ratio: var(--logo-ratio);
				background: $img-logo 0 0 / contain no-repeat;
			}
			@include mobile {
				display: none;
			}
		}
	}
}
