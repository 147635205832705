// AI
.ai-top-title {
	margin:0 0 pc-vh(90) 0;
	font-size: pc-vw(60);
	letter-spacing: 0.01em;
	@include mobile {
		margin:0 0 rem(24) 0;
		font-size: rem(28);
	}
}
.al-icon-title {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top:0;
		left:0;
		display: block ;
		width:pc-vw(80);
		aspect-ratio: 1/1;
		background-image: url(../img/sub/ai-h-icon.svg);
		background-size: contain;
		background-repeat: no-repeat;
		@include mobile {
			position: static;
			width: rem(54);
			margin:0 0 rem(14) 0;
		}
	}
	.iblock {
		margin:0 0 0 pc-vw(120);
		@include mobile {
			margin:0;
		}
	}
}
.ai-icon-title-sub {
	margin:0 0 0 pc-vw(120);
	font-size: pc-vw(20,14);
	@include mobile {
		margin:0;
	}
}
.ai-g-col-group {
	margin:0 0 0 pc-vw(120);
	@include mobile {
		margin:0;
	}
}
.ai-g-title-2 {
	margin:0 0 pc-vh(60) 0;
	@include mobile {
		margin:0 0 rem(24) 0;
	}
}
.ai-ar {
	.ai-ar-inner {
		margin:pc-vw(280) 0 0 0;
		@include mobile {
			margin:rem(80) 0 0 0;
		}
		&.ai-2 {
			ul.swiper-wrapper {
				li {
					display: flex;
					align-items: center;
					justify-content: center;
					height:pc-vw(584);
					@include mobile {
						height:rem(870);
					}
					&.ty-1 {
						padding:pc-vw(80);
					}
					&.ty-2 {
						padding:pc-vw(40);
						background-color: #f4f4f4;
					}
					img {
						height:100%;
						&.pc {
							display: block;
							@include mobile {
								display: none;
							}
						}
						&.mo {
							display: none;
							@include mobile {
								display: block;
							}
						}
					}
				}
			}
			[data-swiper].swiper-ty .swiper {
				&[activeindex="activeIndex1"] {
					--shadow-size : none;
				}
			}
		}
		&.ai-3 {
			h4.g-title-2 {
				display: flex;
				@include mobile {
					flex-direction: column;
					margin:0 0 rem(40) 0;
				}
				&:before {
					content: '';
					display: block;
					width:pc-vw(90);
					height:pc-vh(90);
					margin:0 pc-vw(40) 0 0;
					background-image: url(../img/sub/ai-h-icon.svg);
					background-size: contain;
					background-repeat: no-repeat;
					@include mobile {
						width:rem(54);
						height:rem(54);
						margin:0 0 rem(14) 0;
					}
				}
			}
			div.g-col-group {
				div.col {
					@include mobile {
						margin: 0 0 rem(24) 0;
					}
				}
			}
			ul.ty-img-title-disc {
				@include mobile {
					padding:0;
					> li {
						display: flex;
						align-items: center;
						padding:pc-vw(64) 0;
						div.img {
							@include mobile {
							}
							img {
								transform: scale(1);
							}
						}
						div.p-wrap {
							transition: none;
							@include mobile {
								transform: translateY(0);
							}
						}
					}
				}
			}
		}
		&.ai-4 {
			position: relative;
			padding:pc-vh(280) 0 0 0;
			background-color: #030281;
			color:#fff;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width:150%;
				height:calc(100% + pc-vh(200));
				top:0;
				left:50%;
				transform: translateX(-50%);
				background-color: #030281;
				z-index:-1;
				@include mobile {
					width:calc(100% + rem(40));
				}
			}
			@include mobile {
				padding: rem(80) rem(10);
				margin: -1rem;
				&:before { height:calc(100% + rem(82)); }
			}
			ul.video-list {
				margin:rem(60) 0 0 0;
				li {
					display: flex;
					&:not(:last-child) {
						margin-bottom: pc-vh(140);
					}
					@include mobile {
						flex-direction: column;
						&:not(:last-child) {
							margin-bottom: rem(40);
						}
						> a {
							width:100%;
						}
					}

					span.video-thumb {
						pointer-events: none;
						position:relative;
						display: block;
						border-radius: rem(10);
						overflow: hidden;
						&:before {
							content:'';
							position:absolute;
							top:0;
							left:0;
							display: block;
							width:100%;
							height:100%;
							background-color: rgba(17, 17, 17, 0.80);
							background-image: url(../img/sub/ai-icon-play.svg);
							background-size:rem(90) rem(90);
							background-repeat: no-repeat;
							background-position: center;
							z-index:2;
							@include mobile {
								background-size:rem(40) rem(40);
							}
						}

						img {
							position:absolute;
							top:0;
							left:50%;
							transform: translateX(-50%);
							width:100%;
							&.pc {
								display: block;
							}
							&.mo {
								display: none;
							}
							@include mobile {
								position: static;
								transform: none;
								&.pc {
									display: none;
								}
								&.mo {
									display: block;
								}
							}
						}
					}
					div.p-wrap {

						p.title {
							margin:0 0 pc-vh(40) 0;
							font-size:pc-vw(28);
							font-weight: 600;
							line-height: 1.35;
							letter-spacing: -0.01em;
							@include mobile {
								margin:rem(32) 0 rem(16) 0;
								font-size:rem(24);
							}
						}
						p.sub-disc {
							letter-spacing: -0.02em;
							@include mobile {
								font-size:rem(14);
							}
						}
					}
					&:nth-child(1) {
						align-items: flex-end;
						@include mobile {
							align-items: flex-start;
						}
						span.video-thumb {
							width:pc-vw(580);
							aspect-ratio: 10/6.9;
							margin:0 pc-vw(60) 0 0;
							@include mobile {
								width:100%;
								aspect-ratio: 16/9;
							}

							&.video-thumb {
								width:pc-vw(360);
								aspect-ratio: 1/1;
								margin-left:pc-vw(190);
								margin-right:pc-vw(60);
								@include mobile {
									width:100%;
									aspect-ratio: 16/9;
									margin:0;
								}
							}
						}
						&.uno-video {
							span.video-thumb {
								width:pc-vw(1060);
								aspect-ratio: 16/9;
								margin: 0;
								@include mobile {
									width:100%;
									aspect-ratio: 16/9;
									margin:0;
								}
							}
						}
					}
					&:nth-child(2) {
						flex-direction: row-reverse;
						@include mobile {
							flex-direction: column;
						}
						> a {
							display: flex;
							justify-content: flex-end;
							@include mobile {
								justify-content: flex-start;
							}
						}
						span.video-thumb {
							width:pc-vw(340);
							aspect-ratio: 10/15.3;
							background-color: #fff;
							flex-direction: column;
							@include mobile {
								width:100%;
								aspect-ratio: 16/9;
							}
							img {
								height:pc-vh(550);
								transform: translateX(-50%);
								@include mobile {
									height: auto;
									transform: none;
								}
							}
						}
						div.p-wrap {
							width:calc(100% - pc-vw(340));
							display: flex;
							flex-direction: column;
							justify-content: center;
							margin-left:pc-vw(190);
							@include mobile {
								width:100%;
								margin:0;
								justify-content: flex-start;
							}
						}
					}
					&:nth-child(3) {
						align-items: flex-end;
						@include mobile {
							align-items: flex-start;
						}
						span.video-thumb {
							width:pc-vw(360);
							aspect-ratio: 1/1;
							margin-left:pc-vw(190);
							margin-right:pc-vw(60);
							@include mobile {
								width:100%;
								aspect-ratio: 16/9;
								margin:0;
							}
						}
					}
				}
			}
		}
		&.ai-5 {
			@include mobile {
				padding:rem(60) 0;
				background-color: #f4f4f4;
			}
			h5.g-title-2 {
				@include mobile {
					text-align: center;
				}
			}
			div.inner-table-img {
				padding:pc-vh(123) pc-vw(186);
				background-color: #f4f4f4;
				border-radius: rem(6);
				@include mobile {
					padding:0;
					background: none;
					border-radius: 0;
				}
				img {
					width:100%;
					&.pc {
						display: block;
						@include mobile {
							display: none;
						}
					}
					&.mo {
						display: none;
						@include mobile {
							display: block;
						}
					}
				}
			}
		}
		&.ai-6 {
			@include mobile {
				padding:0 0 rem(80) 0;
			}
		}
		&.ai-pedia {
			div.inner-table-img {
				img {
					width:100%;
					&.pc {
						display: block;
						@include mobile {
							display: none;
						}
					}
					&.mo {
						display: none;
						@include mobile {
							display: block;
						}
					}
				}
			}
		}
		h5.g-title-2 {
			margin-bottom:pc-vh(60);
			@include mobile {
				margin-bottom:rem(20);
			}
		}
	}
	@include mobile {
		padding-bottom: rem(82) !important;
		margin-bottom: rem(-83) !important;
	}
}
// 여기서부터 추가된 Data Loader
.ai-visual-1 {
	@extend .ai-data-loader-visual-1;
}

.ai-data-loader-visual-1 {
	&.ty-center-icon {
		position: relative;
		width:100%;
		aspect-ratio: 10/3.04;
		margin:pc-vh(80) 0 0 0;
		border-radius: rem(10);
		background-image: url(../img/sub/dataloader-common-bg.png);
		background-size: 150%;
		background-position: center;
		background-repeat: no-repeat;
		transition: all 600ms;
		&.ani {
			background-size: 100%;
			span.img {
				animation: ani-bounce-in 600ms $ani-time ease both;
			}
		}
		@include mobile {
			margin-top:rem(80);
			aspect-ratio: 10/6.25;
			background-image: url(../img/sub/dataloader-common-bg-mo.png);
		}
		span.img {
			position:absolute;
			width:17.7%;
			top:68%;
			left:41.15%;
			transform: translateX(-50%);
			@include mobile {
				width:33.75%;
				left:33.125%;
			}
			img {
				width:100%;
			}
		}
	}
}

.ai-data-loader-container {
	padding: 0 110px;
	@include mobile {
		padding: 0;
	}

	ul.ty-img-title-disc>li {
		align-items: flex-start;
		div.p-wrap ul.mg-ty li+li {
			margin: 0;
		}
	}

	div.inner-table-img {
		padding:pc-vh(123) pc-vw(186);
		background-color: #f4f4f4;
		border-radius: rem(6);
		@include mobile {
			padding:0;
			background: none;
			border-radius: 0;
		}
		img {
			width:100%;
			&.pc {
				display: block;
				@include mobile {
					display: none;
				}
			}
			&.mo {
				display: none;
				@include mobile {
					display: block;
				}
			}
		}
	}

	article {
		margin:pc-vw(280) 0 0 0;
		@include mobile {
			margin:rem(80) 0 0 0;
		}
	}
}

.ai-visual-2 {
	@extend .ai-data-loader-visual-2;
}

.ai-data-loader-visual-2 {
	&.ty-center-icon {
		position: relative;
		width:100%;
		aspect-ratio: 10/4.7;
		margin:pc-vh(50) 0 0 0;
		border-radius: rem(10);
		background-image: url(../img/sub/dataloader-sub-bg.png);
		background-size: 150%;
		background-position: center;
		background-repeat: no-repeat;
		transition: all 600ms;
		&.ani {
			background-size: 100%;
			span.img {
				animation: ani-bounce-in 600ms $ani-time ease both;
			}
		}
		@include mobile {
			width: 100%;
			margin-left: 0;
			margin-top:rem(20);
			aspect-ratio: 10/6.25;
			background-image: url(../img/sub/dataloader-sub-bg-mo.png);
		}
		span.img {
			position:absolute;
			width:17.7%;
			top:68%;
			left:41.15%;
			transform: translateX(-50%);
			@include mobile {
				width:33.75%;
				left:33.125%;
			}
			img {
				width:100%;
			}
		}
	}
}

.ai-data-loader-process {
	position: relative;
	margin-top:rem(200);
	padding:pc-vh(200) 0 0 0;
	background-color: #030281;
	color:#fff;

	&:before {
		content: '';
		position: absolute;
		display: block;
		width:150%;
		height:calc(100% + pc-vh(200));
		top:0;
		left:50%;
		transform: translateX(-50%);
		background-color: #030281;
		z-index:-1;
		@include mobile {
			width:calc(100% + rem(40));
		}
	}
	@include mobile {
		margin-top:rem(80);
		padding:rem(60) 0 0 0;
		&:before { height:calc(100% + rem(82)); }
	}

	.ai-data-loader-container {
		padding: 0 rem(110);
		@include mobile {
			padding: 0 rem(10);
		}
	}

	div.inner-table-img {
		margin-bottom: rem(20);
		padding: 0;
		background: transparent;
	}
}

// 여기서부터 추가된 HANCOM Auth
.sdk-auth-container {
	padding: pc-vh(100, 80) pc-vw(40, 80) 0;
	@include mobile {
		padding-left: 0;
		padding-right: 0;
	}
	article {
		margin: pc-vw(280) 0 0 0;
		@include mobile {
			margin: rem(80) 0 0 0;
		}
	}
	.g-title-2.text-center {
		text-align: center;
	}
	ul.ty-img-title-disc>li div.p-wrap ul.mg-ty li+li {
		margin: 0;
	}
	ul.ty-big-circle-icon {
		h5 {
			margin-top: pc-vw(20, 24);
			font-size: pc-vw(18, 24);
			font-weight: 600;
			text-align: center;
		}
	}
}


.sdk-auth-visual-1 {
	@extend .sdk-auth-visual-1;
}

.sdk-auth-visual-1 {
	&.ty-center-icon {
		position: relative;
		width: 100%;
		aspect-ratio: 10/3.04;
		margin: pc-vh(80) 0 0 0;
		border-radius: rem(10);
		background-image: url(../img/sub/sdk-auth-main-img-pc.png);
		background-size: 150%;
		background-position: center;
		background-repeat: no-repeat;
		transition: all 600ms;
		&.ani {
			background-size: 100%;
			span.img {
				animation: ani-bounce-in 600ms $ani-time ease both;
			}
		}
		@include mobile {
			margin-top: rem(80);
			aspect-ratio: 10/6.25;
			background-image: url(../img/sub/sdk-auth-main-img-mo.png);
		}
		span.img {
			position: absolute;
			width: 17.7%;
			top: 68%;
			left: 41.15%;
			transform: translateX(-50%);
			@include mobile {
				width: 33.75%;
				left: 33.125%;
			}
			img {
				width: 100%;
			}
		}
	}
}

.sdk-auth-visual-2 {
	@extend .sdk-auth-visual-2;
}

.sdk-auth-visual-2 {
	&.sdk-auth-visual-img {
		position: relative;
		width: 100%;
		aspect-ratio: 10/3.2;
		margin:pc-vh(80) 0 0 0;
		background-image: url(../img/sub/sdk-auth-pl-img-pc.png);
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		transition: all 600ms;
		&.ani {
			background-size: 100%;
			span.img {
				animation: ani-bounce-in 600ms $ani-time ease both;
			}
		}
		@include mobile {
			width: 100%;
			margin-left: 0;
			margin-top: rem(60);
			aspect-ratio: 10/6.2;
			background-image: url(../img/sub/sdk-auth-pl-img-mo.png);
		}
		span.img {
			position: absolute;
			width: 17.7%;
			top: 68%;
			left: 41.15%;
			transform: translateX(-50%);
			@include mobile {
				width: 33.75%;
				left: 33.125%;
			}
			img {
				width: 100%;
			}
		}
	}
}

ul.sdk-auth.ty-img-title-disc {
	li {
		align-items: flex-start;
		div.img {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 170px;
			border-radius: rem(10);
			background-color: #f0f0f0;
			aspect-ratio: 10/6.47;
			img {
				height: pc-vw(125);
				width: auto;
			}
		}
		ul.bul-dot.mg-ty {
			margin: pc-vh(20) 0 0 0;
			font-size: pc-vw(16, 12);
		}
	}
}

ul.sdk-auth-img {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: rem(60) 0 0 0;
	li {
		width: pc-vw(396);
		margin-right : pc-vw(18);
		text-align: left;
		img {
			width: 100%;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	p.title {
		margin: 0 0 pc-vh(10) 0;
		font-size: pc-vw(28);
		font-weight: 600;
		line-height: 1.35;
		letter-spacing: -0.01em;
		@include mobile {
			margin: 0 0 rem(16) 0;
			font-size: rem(24);
		}
	}
}

ul.sdk-auth-icon-list {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: pc-vh(60) 0 0 0;
	>li {
		width: pc-vw(396);
		margin-right : pc-vw(18);
		padding: pc-vh(32, 20) pc-vw(32, 20) pc-vh(64, 38);
		text-align: left;
		color: #fff;
		background-color: #1464F4;
		border-radius: rem(6);
		img {
			margin-bottom: pc-vh(10);
		}
		&:last-child {
			margin-right: 0;
		}
		p.title {
			margin:0 0 pc-vh(10) 0;
			font-size: pc-vw(28);
			font-weight: 600;
			line-height: 1.35;
			letter-spacing: -0.01em;
			@include mobile {
				margin: 0 0 rem(16) 0;
				font-size: rem(24);
			}
		}
		ul.bul-dot.mg-ty {
			margin: pc-vh(20) 0 0 0;
			font-size: pc-vw(16, 12);
			>li {
				&:before {
					background-color: #fff;
				}
				& + li {
					margin-top: pc-vh(10);
				}
			}
		}
	}
	@include mobile {
		flex-direction: column;
		align-items: center;
		padding: pc-vh(20) 0 0 0;
		> li {
			width: 100%;
			margin-right: 0;
			margin-bottom: pc-vh(20);
			img {
				width: rem(40);
				height: rem(40);
			}
		}	
	}
}

ul.sdk-auth-img-grid {
	display: flex;
	padding: pc-vh(60) 0 0 0;
	> li {
		flex-direction: row;
		width: 25%;
		margin-right: pc-vh(60);		
		&:last-child {
			margin-right: 0;
		}
		img {
			width: 100%;
			max-width: 226px;
			margin-bottom: pc-vw(32);
		}
	}
		
	@include mobile {
		flex-wrap: wrap;
		> li {
			flex-direction: column;
			width: calc(50% - pc-vh(10));
			padding-bottom: rem(60);
			margin-right: pc-vh(20);
			&:nth-child(even) {
				margin-right: 0;
			}
			img {
				margin-bottom: pc-vw(24);
			}		
		}
		ul.bul-dot.mg-ty {
			margin: pc-vh(20) 0 0 0;
			font-size: pc-vw(16, 12);
			>li {
				&:before {
					background-color: #fff;
				}
				& + li {
					margin-top: pc-vh(10);
				}
			}
		}
	}
	dl {
		dt {
			margin: 0 0 pc-vh(10) 0;
			font-size: pc-vw(28);
			font-weight: 600;
			line-height: 1.35;
			letter-spacing: -0.01em;
			@include mobile {
				margin: 0 0 rem(16) 0;
				font-size: rem(24);
			}
		}
		dd {
			position: relative;
			margin: pc-vh(20) 0 0 0;
			padding-left: 1.2rem;
			font-size: pc-vw(16, 12);
			word-break: break-word;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: .6em;
				width: 0.4rem;
				height: 0.4rem;
				border-radius: 100%;
				background-color: #000;
			}
			& + dd {
				margin-top: pc-vh(10);
			}
		}
	}
}


ul.block-type-list {
	display: flex;
	justify-content: center;
	width: 100%;
	>li {
		width: 100%;
		margin-right : pc-vw(18);
		padding: pc-vh(32, 20) pc-vw(32, 20);
		text-align: left;
		color: #fff;
		background-color: #1464F4;
		border-radius: rem(6);

		&:last-child {
			margin-right: 0;
		}
		p.title {
			margin: 0 0 pc-vh(10) 0;
			font-size: pc-vw(28);
			font-weight: 600;
			line-height: 1.35;
			letter-spacing: -0.01em;
			@include mobile {
				margin: 0 0 rem(16) 0;
				font-size: rem(24);
			}
		}
		p.description {
			margin: pc-vh(20) 0 0 0;
			font-size: pc-vw(16, 12);
		}
	}
	@include mobile {
		flex-direction: column;
		align-items: center;
		padding: pc-vh(20) 0 0 0;
		> li {
			width: 100%;
			margin-right: 0;
			margin-bottom: pc-vh(20);
		}	
	}
}

.icon-list {
	display: flex;
    flex-wrap: wrap;
	align-items: flex-start;
	text-align: center;
	&.col3 {
		li {
			width: calc((100% - pc-vw(40)) / 3);
			padding: pc-vh(40) pc-vw(32);
			@include mobile {
				width: 100%;
				padding: pc-vh(24) 0;
				text-align: left;
				img {
					float: left;
					max-width: 20%;
					margin-right: pc-vw(34);
				}
			}
		}
	}
	p.title {
		margin: 0 0 pc-vh(10) 0;
		font-size: pc-vw(28);
		font-weight: 600;
		line-height: 1.35;
		letter-spacing: -0.01em;
		@include mobile {
			margin: 0 0 rem(8) 0;
			font-size: rem(20);
		}
	}
	p.description {
		margin: pc-vh(14) 0 0 0;
		font-size: pc-vw(16, 12);
		color: #7A7C85;
	}
}