.btn {
	&-more {
		--hover-is: 0;
		display: inline-block;
		line-height: 1.2;
		border: 1px solid rgba(0,0,0,calc(var(--hover-is) + 0.3));
		border-radius: rem(50);
		font-weight: 400;
		transition: border $ani-time;
		&:hover {
			--hover-is: 1;
		}
		&.dark {
			border-color: rgba(255,255,255,calc(var(--hover-is) + 0.3));
			color: white;
		}
		@include desktop {
			padding: rem(9) rem(24);
			font-size: pc-vw(20, 16);
			letter-spacing: -0.0375em;
			.ico-arrow {
				margin-right: rem(-14);
			}
		}
		@include mobile {
			padding: rem(5) rem(20);
			font-size: rem(12);
			letter-spacing: -0.01667em;
			.ico-arrow {
				margin-right: rem(-15);
			}
		}
	}
	&-send {
		display: block;
		width: 100%;
		border-radius: var(--radius-fm);
		font-weight: 600;
		color: #fff;
		background: linear-gradient(90deg, #0B34C7 -11.55%, #18D9DA 109.28%);
		&:disabled {
			background: #C0C0C0;
		}
		@include desktop {
			font-size: rem(20);
			height: pc-vh(80);
		}
		@include mobile {
			font-size: rem(14);
			height: rem(50);
		}
	}
}

.ico-arrow {
	display: inline-block;
	aspect-ratio: 1/1;
	border-radius: 100%;
	vertical-align: middle;
	background: #111 $svg-arrow-go 50% 50% / 50% 50% no-repeat;
	a:hover &, button:hover & {
		animation: ani-hover-arrow $ani-time;
	}
	.dark &, &.dark {
		background-color: white;
		background-image: $svg-arrow-go-black;
	}
	@include desktop {
		width: pc-vw(50, 36);
		margin-left: rem(6);
	}
	@include mobile {
		width: rem(28);
		margin-left: rem(16);
	}
}

// Share ICON
@each $name, $aspect, $img in $sns-list {
	.sns-#{$name} {
    display: inline-block;
    aspect-ratio: #{$aspect}/1;
		white-space: nowrap;
		overflow: hidden;
		font-size: rem(1);
		text-indent: -999px;
		background: $img center center / contain no-repeat;
	}
}