// Office
.office-visual {
	position: relative;
	--ani-active: 0;
	&.ani {
		--ani-active: 1;
	}
	@include desktop {
		margin-top: pc-vw(374);
	}
	@include mobile {
		display: flex;
		flex-direction: column;
		margin-top: rem(80);
	}
	img {
		max-width: 100%;
		display: block;
	}
	.img {
		display: block;
		aspect-ratio: 365/95;
		border-radius: rem(10);
		background: #1050F0;
		img {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 2;
		}
		@include mobile {
			aspect-ratio: 31.9/38;
			img { display: none; }
		}
	}
	.deco {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		text-align: center;
		clip-path: polygon(-100% -100%, 100% -100%, 100% 100%, -100% 100%);
		img {
			transform: translateY(calc((1 - var(--ani-active)) * 100%));
			max-width: 43.35%;
			margin-left: auto;
			margin-right: auto;
			transition: all 1s;
		}
		@include mobile {
			left: rem(-20);
			right: rem(-20);
			width: auto;
			img {
				max-width: 100%;
				transform: scale(1.15) translateY(calc((1 - var(--ani-active)) * 100%));
				transform-origin: center bottom;
			}
		}
	}
	.icons {
		display: block;
		display: flex;
		@include desktop {
			position: absolute;
			bottom: 100%;
			left: 0;
			height: pc-vw(80);
			transform: translateY(rem(-20));
			.item {
				height: 100%;
				& + .item {
					margin-left: pc-vw(24);
				}
			}
		}
		@include mobile {
			justify-content: space-between;
			align-items: center;
			order: -1;
			height: rem(51.45);
			margin-bottom: rem(72.55);
		}
	}
}
.column-card {
	--rad: #{rem(10)};
	--text-b: #111;
	--text-w: #fff;

	@include desktop {
		display: flex;
		.effect-fade:not(.ani) & {
		}
		.effect-fade & {
			.card-item {
				transition: opacity 600ms $ani-time, transform 600ms $ani-time;
			}
		}
		.effect-fade:not(.ani) &.active,
		.effect-fade &.old-active:not(.active) {
			.card-item {
				transition-delay: 0ms, 0ms;
				transition-duration: $ani-time, $ani-time;
				&:nth-child(odd) {
					opacity: 0;
					transform: translateY(rem(-60));
				}
				&:nth-child(even) {
					opacity: 0;
					transform: translateY(rem(60));
				}
			}
		}
		.effect-fade &:not(.active){
			.card-item {
				&:nth-child(odd) {
					opacity: 0;
					transform: translateY(rem(60));
				}
				&:nth-child(even) {
					opacity: 0;
					transform: translateY(rem(-60));
				}
			}
		}
	}
	.card-item {
		&.ty-word {
			--point-color1: #521BB7;
			--point-color2: #D3CDFA;
			--text-color: var(--text-w);
		}
		&.ty-cell {
			--point-color1: #1CAD95;
			--point-color2: #DCFFF8;
			--text-color: var(--text-b);
		}
		&.ty-show {
			--point-color1: #FA7C50;
			--point-color2: #FFEBE4;
			--text-color: var(--text-b);
		}
		&.ty-pdf {
			--point-color1: #E5294A;
			--point-color2: #FFEDF0;
			--text-color: var(--text-w);
		}

		display: block;
		border-radius: var(--rad);
		background-color: var(--point-color2);
		color: var(--text-b);
		font-weight: 400;
		.cap {
			color: var(--point-color1);
			font-weight: 600;
		}
		.text-ty {
			font-weight: 600;
		}
		.img {

			img {
				max-width: 100%;
				vertical-align: top;
			}
		}
		@include desktop {
			flex: 1;
			padding: pc-vw(40) pc-vw(95) pc-vw(40) pc-vw(40);
			font-size: pc-vw(20,14);
			line-height: 1.5;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.cap {
				margin-bottom: pc-vw(24);
				font-size: pc-vw(16,12);
				line-height: 1.25;
			}
			.text-ty {
				margin-bottom: pc-vw(20);
				font-size: pc-vw(28,20);
				line-height: 1.357;
			}
			.img {
				margin-top: pc-vw(20);
				text-align: right;
				transform: translateX(pc-vw(40));
				&.ty-illu {
					margin-top: pc-vw(40);
					transform: translateX(0);
					text-align: center;
					img {
						width: 52.6%;
					}
				}
				img {
					max-width: 76.31%;
				}
			}
			&:nth-child(even) {
				padding-right: pc-vw(75);
				margin-left: calc(pc-vw(60) * -1);
				top: pc-vh(40);
			}
		}
		@include mobile {
			padding: rem(28) rem(20);
			font-size: rem(14);
			line-height: 1.428;
			letter-spacing: -0.02em;
			.cap {
				margin-bottom: rem(16);
				font-size: rem(12);
				line-height: 1.25;
				letter-spacing: 0;
			}
			.text-ty {
				margin-bottom: rem(16);
				font-size: rem(24);
				line-height: 1.208;
			}
			.img {
				width:100%;
				margin-top: rem(32);
				img {
					width:100%;
				}
			}
			& + .card-item {
				margin-top: rem(12);
			}
		}
		&:nth-child(even) {
			background-color: var(--point-color1);
			color: var(--text-color);
			.cap {
				color: var(--text-w);
			}
		}
	}
}